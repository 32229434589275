
import { Component, Vue } from 'vue-property-decorator'

// JP: All lang="ts" scripts must define components even if empty.
// If you delete these lines you will get the error (At "vue": "^2.6.10" and "vuetify": "^2.2.1"):
// TypeError: Cannot set property 'components' of undefined
//    at installComponents (installComponents.js:11)

@Component({
  metaInfo() {
    const isInviteSetupView = this.$route.query.invited === '1'

    return {
      title: `${isInviteSetupView ? 'Join your team' : 'Log in'} to access & create 3D presentations & more`,
      meta: [
        {
          name: 'description',
          content: `${isInviteSetupView ? 'Join your team' : 'Log in'} to manage your team users, 3D assets, branding, and sharing settings.`,
        },
      ],
    }
  },
})

export default class PreLoginVue extends Vue {
  private isInviteSetupView: boolean = false

  protected created() {
    this.isInviteSetupView = this.$route.query.invited === '1'
  }

  private get pageHeading(): string {
    return this.isInviteSetupView ? 'Join your team' : 'Team dashboard'
  }

  private get pageCopy(): { [key:string]: string } {
    return {
      description: this.isInviteSetupView ? `You've been invited to join a team in JigSpace.` : 'Log in to manage your team users, 3D assets, branding, and sharing settings.',
      infoCopyHtml: this.isInviteSetupView ? `Set a new password to complete your setup.` : `<strong>Don't have an account?</strong>&nbsp;<a href="https://account.jig.space/signup" class="login__link" target="_blank">Get started</a>.`,
    }
  }

  private get ctaCopy(): string {
    return this.isInviteSetupView ? 'Finish setup' : 'Log in'
  }

  private handleLoginAction() {
    if (this.isInviteSetupView) {
      // Trigger universal login with custom param attached,
      // so that universal loign form can show custom copy for better UX.
      this.$auth0.loginWithRedirect({
        appState: { target: '/my-jigs' },
        prompt: 'login',
        authorizationParams: {
          rememberLastLogin: false,
          isInvitedSetupView: true,
          allowSignUp: false,
        },
      })
    } else {
      this.$router.push({ path: '/my-jigs' })
    }
  }
}
